import React from 'react';
import config from '../../config';

export default function Footer() {
    return (
        <section id="footer">
            <div className="inner">
                <h2 className="major">Get in touch</h2>
                <p>
                    Feel free to ask any questions. We are here to help you!
                </p>

                <ul className="contact">
                    <li className="fa-home">{config.address}</li>

                    {config.socialLinks.map(social => {
                        const {icon, url, name} = social;
                        return (
                            <li className={`${icon}`} key={url}>
                                <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
}
