module.exports = {
  siteTitle: 'Final Commit - mobile development', // <title>
  manifestName: 'Final Commit',
  manifestShortName: 'Final Commit', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo.png',
  heading: 'Final Commit',
  subHeading: 'flutter mobile development',
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/andrzej-kaczynski/',
    },
  ],
  address: 'Poznań, Poland',
  firebaseFunctionsUrl: "https://us-central1-finalcommit-88ac9.cloudfunctions.net",
};
